import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

//images
import agents1 from "../images/new-york-insureds.png"
import agents2 from "../images/make-payments.png"
import agents3 from "../images/special-insureds.png"
import agents4 from "../images/tips-insured.png"

import SpecialInformation from "../../static/assets/re_consolidated_amend_pt_405_27a_27c_new_216_text.pdf"
import SpecialNewJersey from "../../static/assets/New-Jersey-blt20_17.pdf"
import Tips from "../../static/assets/Additional-Guidance-for-Our-Insureds.v2.pdf"

//data
// import { PremiumFinanceData } from "../components/data/data"

const CovidPage = () => {
  return (
    <Layout>
      <SEO
        title="Important Messages From National Partners Regarding Covid-19 - National Partners"
        description="Amid escalating concerns about the impact of COVID-19, we wanted to communicate key information about National Partners and servicing of your premium finance loan: While promoting safety for our staff’s well-being, we wanted to reassure our clients that we are working at full capacity and all business services are available to you. If you have […]"
      />
      <div className="page-headline">
        <div
          className="hero-headline covid-page"
          style={{ padding: "10rem 0" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="section-heading text-center">
                  <div className="hero-body">
                    <h1 className="hero-page-title mb-4">
                      Important Messages From National Partners Regarding
                      Covid-19
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content pb-280 pt-0 covid-wrapper">
        <section className="pb-0">
          <article>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-lg-auto">
                  <div className="section-heading text-center">
                    <h6 className="section-subtitle text-gold text-uppercase">
                      our
                    </h6>
                    <hr className="section-hr" />
                    <h2 className="section-title">
                      Resources for Valued Agents and Insureds
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article>
            <div className="container">
              <div className="row" style={{ marginTop: "5rem" }}>
                <div className="col-lg-3 col-md-6 mb-4">
                  <div className="resources-item text-center">
                    <a href={SpecialInformation} target="_blank" rel="noopener">
                      <img
                        src={agents1}
                        className="img-fluid resources-img"
                        alt="Resources 1"
                      />
                      <h6 className="py-2">
                        Special Information for New York Insureds
                      </h6>
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                  <div className="resources-item text-center">
                    <Link to="/make-a-payment">
                      <img
                        src={agents2}
                        className="img-fluid resources-img"
                        alt="Resources 2"
                      />
                      <h6 className="py-2">How to Make Payments</h6>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                  <div className="resources-item text-center">
                    <a href={SpecialNewJersey} target="_blank" rel="noopener">
                      <img
                        src={agents3}
                        className="img-fluid resources-img"
                        alt="Resources 3"
                      />
                      <h6 className="py-2">
                        Special Information for New Jersey Insureds
                      </h6>
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-5">
                  <div className="resources-item text-center">
                    <a href={Tips} target="_blank" rel="noopener">
                      <img
                        src={agents4}
                        className="img-fluid resources-img"
                        alt="Resources 4"
                      />
                      <h6 className="py-2">Tips for Insureds</h6>
                    </a>
                  </div>
                </div>
                <div className="col-lg-10 mx-auto">
                  <h2>
                    Amid escalating concerns about the impact of COVID-19, we
                    wanted to communicate key information about National
                    Partners and servicing of your premium finance loan:
                  </h2>
                  <ul>
                    <li>
                      While promoting safety for our staff’s well-being, we
                      wanted to reassure our clients that we are working at full
                      capacity and all business services are available to you.
                    </li>
                    <li className="mt-4">
                      If you have any questions about your loan or to request a
                      new password, please reach out to us{" "}
                      <b>Monday – Friday 7:30 AM to 5:00 PM MST</b> at:
                      <b>800-506-8901</b>; 24 x 7 online at{" "}
                      <Link to="/">www.NationalPartners.com</Link> or via email
                      at{" "}
                      <a href="mailto:customerservice@nationalpartners.com">
                        customerservice@nationalpartners.com
                      </a>
                    </li>
                    <li className="mt-4">
                      We are here to support you during these extraordinary
                      times. Special thanks and well wishes to all the health
                      care providers and support members. Thank you for your
                      business and we’re in this together!
                    </li>
                    {/* <li>Integration with DocuSign</li>
                  <li>
                    Multiple quote options so client may determine best
                    course-of-action for business
                  </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
    </Layout>
  )
}

export default CovidPage
